@import '../../styles/colors';

.Footer {
  position: relative;
  margin-top: 174px;
  padding: 32px 150px 52px 150px;
  background: rgba(0, 0, 0, 0.3);

  @media (max-width: 1100px) {
    padding: 32px 38px 52px 38px;
  }

  @media (max-width: 768px) {
    margin-top: 68px;
  }

  @media (max-width: 710px) {
    align-items: center;
  }

  .contactsBackground {
    position: absolute;
    //padding for safari
    padding: 0 40px 0 40px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 300px;
    color: $BEIGE;
    font-style: italic;
    font-family: Missionary-Regular;
    opacity: 0.1;
    z-index: -1;
    line-height: 0;
    cursor: default;
    user-select: none;

    @media (max-width: 1162px) {
      font-size: 250px;
    }

    @media (max-width: 957px) {
      font-size: 200px;
    }

    @media (max-width: 779px) {
      font-size: 150px;
    }

    @media (max-width: 578px) {
      font-size: 100px;
    }
  }

  .logoImg {
    width: 155px;
    height: 74px;
    margin-bottom: 32px;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 710px) {
      flex-direction: column;
      align-items: center;
    }

    .dubaiMapImg {
      width: 370px;
      height: 168px;
      object-fit: cover;
      margin-bottom: 32px;
      border-radius: 16px;
      cursor: pointer;

      @media (max-width: 890px) {
        width: 183px;
      }

      @media (max-width: 710px) {
        width: 370px;
      }

      @media (max-width: 375px) {
        width: 183px;
      }
    }

    .locationAndFollowMobileWrap {
      display: flex;
    }

    @media (max-width: 710px) {
      .contactsWrap {
        text-align: center;
      }

      .locationWrap {
        margin-right: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .title {
      color: $WHITE;
      margin-bottom: 32px;
      font-size: 24px;
      font-weight: 700;
      font-family: Raleway-Regular;
    }

    .infoItem {
      display: flex;
      margin-bottom: 24px;

      .icon {
        margin-right: 16px;
      }
    }

    .followWrap {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        margin-bottom: 24px;
      }

      .instagramIconContainer {
        padding: 8px;

        path {
          transition: stroke 0.2s ease-in-out;
        }
      }
    }

    .instagramIconContainer:hover {
      path {
        stroke: $BEIGE;
      }
    }

    .text {
      color: $WHITE;
      transition: color 0.2s ease-in-out;
      font-family: Montserrat-Regular;

      cursor: pointer;
    }
    .text:hover {
      color: $BEIGE;
    }
  }
}
