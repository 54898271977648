.FullScreenGallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.74);
  backdrop-filter: blur(14.149999618530273px);
  z-index: 100;

  .customContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .closeIcon {
    position: fixed;
    z-index: 200;
    top: 40px;
    right: 40px;
    cursor: pointer;

    @media (max-width: 768px) {
      top: 16px;
      right: 16px;
    }
  }

  .image-gallery-thumbnails {
    margin-top: 70px;
    button {
      width: 110px;
      height: 110px;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;

      @media (max-width: 1000px) {
        width: 50px;
        height: 50px;
      }
    }
  }

  .controlsContainer {
    position: absolute;
    bottom: -52px;
    left: 50%;
    transform: translateX(-50%);
  }
}
