@import '../../styles/colors';
@import '../../styles/resolutions';

.Slider {
  padding-top: 68px;
  padding-bottom: 100px;

  @media (max-width: $TABLET_LANDSCAPE) {
    padding-bottom: 50px;
  }

  .header {
    margin-top: 38px;
    margin-bottom: 52px;
    display: flex;
    font-family: Missionary-Regular, serif;
    font-size: 55px;
    color: $WHITE;
    flex-direction: column;
    align-items: center;

    @media (max-width: 824px) {
      font-size: 33px;
    }

    @media (max-width: 456px) {
      font-size: 30px;
    }
    @media (max-width: $MOBILE) {
      margin-top: 0;
      margin-bottom: 10px;
    }

    .h1Container {
      border-radius: 65px;
      border: 1px solid $WHITE;
      padding: 0 32px;
      display: flex;
      align-items: center;

      h1 {
        font-size: 62px;
        font-family: Missionary-Regular, serif;

        @media (max-width: 824px) {
          font-size: 33px;
        }

        @media (max-width: 456px) {
          font-size: 23px;
        }

        span {
          font-style: italic;
          color: $BEIGE;
        }
      }
    }
  }

  .sliderContainer {
    display: flex;
    justify-content: center;

    .swiper-slide-active {
      video {
        border: 2px solid $WHITE;
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
      }
    }

    .customSliderContainer {
      width: 787px;
      display: flex;
      justify-content: center;

      @media (max-width: $TABLET_LANDSCAPE) {
        width: 100%;
      }
    }

    .swiper {
      height: 620px;
      padding: 20px 0;

      @media (max-width: $MOBILE) {
        height: 391px;
      }
    }

    .activeCover {
      filter: sepia(0);
    }

    .inactiveCover {
      filter: sepia(0.5);
    }

    .customSlideContainer {
      display: flex;
      justify-content: center;

      .videoContainer {
        position: relative;

        video,
        .poster {
          width: 320px;
          border-radius: 27px;
          transition: 1s;

          @media (max-width: $MOBILE) {
            width: 198px;
            height: 349px;
          }
        }

        .poster {
          height: 513px;

          @media (max-width: $MOBILE) {
            height: 350px;
          }
        }
      }
    }
  }

  .muteContainer {
    display: flex;
    justify-content: center;

    .mute {
      cursor: pointer;
    }
  }

  .controlsContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

#react-burger-menu-btn {
  display: none !important;
}

.bm-burger-bars {
  display: none;
}
