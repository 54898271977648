@import '../../../../styles/colors';

.ChangeLanguageMobile {
  color: $WHITE;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  .mobileLanguagesContainer {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .inactiveLanguage {
    color: #acadb4;
  }

  .activeLanguage {
    color: $WHITE;
  }

  img {
    margin-left: 4px;
  }

  .languages {
    position: absolute;
    top: 30px;
    left: 1px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 20;
  }
}
