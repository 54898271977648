@import '../../styles/colors';
@import '../../styles/resolutions';

.AboutUs {
  position: relative;
  background: url('../../assets/images/aboutUs/background.png') no-repeat;
  background-size: contain;

  @media (max-width: $TABLET_LANDSCAPE) {
    padding: 250px 40px 0 40px !important;
  }

  @media (max-width: 900px) {
    padding: 200px 40px 0 40px !important;
  }

  @media (max-width: 800px) {
    padding: 100px 40px 0 40px !important;
  }

  @media (max-width: $MOBILE) {
    padding: 100px 0 0 0 !important;
  }

  .backgroundBlur {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(15px) brightness(100%);
    background: rgba(0, 0, 0, 0.5);
  }

  .mystery {
    font-size: 300px;
    color: $BEIGE;
    font-style: italic;
    position: absolute;
    top: 0;
    right: 0;
    font-family: Missionary-Regular, serif;
    opacity: 0.1;
    z-index: 3;
    cursor: default;
    user-select: none;

    @media (max-width: 1162px) {
      font-size: 250px;
    }

    @media (max-width: 957px) {
      font-size: 200px;
    }

    @media (max-width: 779px) {
      font-size: 150px;
    }

    @media (max-width: 578px) {
      font-size: 100px;
    }
  }

  .about {
    position: absolute;
    right: 180px;
    top: 350px;
    width: 552px;
    z-index: 3;

    @media (max-width: 700px) {
      width: 100%;
    }

    @media (max-width: 800px) {
      top: 180px;
    }

    @media (max-width: 600px) {
      top: 140px;
    }

    @media (max-width: 890px) {
      right: 0;
    }

    div {
      display: flex;
      width: 100%;
      font-family: Missionary-Regular, serif;
      color: $WHITE;
      line-height: 0.8;
    }

    div:nth-child(1) {
      justify-content: flex-start;
      font-size: 64px;
      color: $BEIGE;
      font-style: italic;

      @media (max-width: 1024px) {
        color: $WHITE;
      }
      @media (max-width: 600px) {
        font-size: 40px;
      }
    }

    div:nth-child(2) {
      justify-content: center;
      font-size: 100px;

      @media (max-width: 600px) {
        font-size: 50px;
      }
    }

    div:nth-child(3) {
      justify-content: flex-end;
      font-size: 64px;

      @media (max-width: 600px) {
        font-size: 40px;
      }
    }
  }

  .textContainer {
    position: absolute;
    right: 180px;
    bottom: 0;
    color: $WHITE;
    z-index: 3;
    display: flex;
    margin-top: 40px;

    @media (max-width: $MOBILE) {
      padding: 0 32px;
    }

    @media (max-width: $TABLET_LANDSCAPE) {
      position: relative;
      right: auto;
      bottom: auto;
      //width: 100%;
      justify-content: flex-end;
    }

    .text {
      width: 460px;

      p {
        padding: 5px 0 5px 0;
      }

      li {
        padding-left: 1em;
      }

      li::before {
        content: '• ';
      }

      .callContainer {
        display: flex;
        justify-content: flex-end;

        .call {
          display: flex;
          align-items: center;
          padding: 8px 24px;
          border-radius: 23px;
          border: 1px solid $WHITE;
          width: auto;
          margin-top: 32px;
          cursor: pointer;
          transition:
            border-color 0.2s ease-in-out,
            color 0.2s ease-in-out;

          .whatsappIcon {
            margin-left: 16px;

            path {
              transition: fill 0.2s ease-in-out;
            }
          }
        }

        .call:hover {
          border-color: $BEIGE;
          color: $BEIGE;

          .whatsappIcon {
            path {
              fill: $BEIGE;
            }
          }
        }
      }
    }
  }
}
