@import '../../styles/colors';

$PADDING_TOP: 253px;

.Gallery {
  margin-top: 178px;
  position: relative;
  background: url('../../assets/images/gallery/background.png') no-repeat top;
  background-size: 100% 1000px;

  @media (max-width: 1580px) {
    background-size: 100% 800px;
  }

  @media (max-width: 1270px) {
    background-size: 100% 600px;
  }

  @media (max-width: 1024px) {
    margin-top: 70px;
    background-size: 100% 400px;
  }

  @media (max-width: 900px) {
    background-size: 100% 210px;
  }

  .backgroundBlur {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    bottom: 600px;
    backdrop-filter: blur(15px) brightness(50%);

    //for Safari
    @media (max-width: 1755px) {
      bottom: 500px;
    }

    @media (max-width: 1490px) {
      bottom: 350px;
    }

    @media (max-width: 660px) {
      bottom: 300px;
    }

    @media (max-width: 610px) {
      bottom: 250px;
    }

    @media (max-width: 525px) {
      bottom: 150px;
    }
    //end
  }

  .u-non-blurred {
    position: relative;
    z-index: 1;
  }

  .gallery {
    position: relative;
    width: 842px;

    @media (max-width: 1024px) {
      width: 682px;
    }

    @media (max-width: 768px) {
      width: 350px;
    }

    @media (max-width: 430px) {
      width: 100%;
    }

    div {
      display: flex;
      width: 100%;
      font-family: Missionary-Regular, serif;
      color: $WHITE;
      line-height: 0.8;
      padding-left: 180px;

      @media (max-width: 1024px) {
        padding-left: 24px;
      }
    }

    div:nth-child(1) {
      justify-content: flex-start;
      font-size: 100px;

      @media (max-width: 768px) {
        font-size: 50px;
      }
    }

    div:nth-child(2) {
      justify-content: center;
      font-size: 64px;

      @media (max-width: 768px) {
        font-size: 40px;
      }
    }
  }

  .galleryBackground {
    position: absolute;
    //padding for safari
    padding: 0 40px 0 40px;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 400px;
    color: $BEIGE;
    font-style: italic;
    font-family: Missionary-Regular;
    opacity: 0.1;
    line-height: 0;
    cursor: default;
    user-select: none;

    @media (max-width: 1162px) {
      font-size: 250px;
    }

    @media (max-width: 957px) {
      font-size: 200px;
    }

    @media (max-width: 779px) {
      font-size: 150px;
    }

    @media (max-width: 578px) {
      font-size: 100px;
    }
  }

  .moreButtonContainer {
    display: flex;
    justify-content: center;

    button {
      margin-top: 12px;
      display: flex;
      cursor: pointer;

      span {
        color: $WHITE;
        font-size: 18px;
        transition: color 0.2s ease-in-out;
      }
    }

    .chevronIcon {
      path {
        transition: stroke 0.2s ease-in-out;
      }
    }

    .chevronIconRotate {
      transform: rotate(0.5turn);
    }

    button:hover {
      path {
        stroke: $BEIGE;
      }

      span {
        color: $BEIGE;
      }
    }
  }
}
