@import 'styles/colors.scss';

@font-face {
  font-family: Raleway-Regular;
  src: url(assets/fonts/Raleway-Regular.ttf);
}

@font-face {
  font-family: Missionary-Regular;
  src: url(assets/fonts/Missionary-Regular.ttf);
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(assets/fonts/Montserrat-Regular.ttf);
}

#root {
  white-space: pre-line;
}

* {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
  box-sizing: border-box;

  font-family: Raleway-Regular, serif;

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
}

body {
  margin: 0;
  background:
    url('./assets//images//slider/background.png') no-repeat top left / 1500px
      80vh,
    url('./assets/images/footer/background.png') no-repeat bottom / 100% 900px,
    $BLACK;

  ::-webkit-scrollbar {
    display: none;
  }
}

html {
  scrollbar-width: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 0;
    margin: 0;
  }
}

img {
  display: block;
}

a {
  text-decoration: none;
  color: $WHITE;
}

h1 {
  font-size: 1em;
  margin: 0;
}

.scrollToTopContainer {
  opacity: 0;
  margin-right: calc(-50px - 63.5px);
  position: fixed;
  z-index: 120;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  transition: 0.3s;

  path {
    transition: stroke 0.2s ease-in-out;
  }
}

.App {
  backdrop-filter: blur(15px) brightness(100%);
}

.scrollToTopContainer:hover {
  path {
    stroke: $BEIGE;
  }
}

p {
  margin: 0;
}

button {
  background: none;
  border: none;
}
