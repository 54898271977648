.GridPhotosMore {
  margin-top: 24px;
  display: grid;
  gap: 24px;
  padding: 0 34px 0 34px;
  grid-template-areas:
    'I I J K'
    'L M N P'
    'L M O P'
    'Q R S S'
    'T R U V';

  @media (max-width: 1024px) {
    margin-top: 16px;
    gap: 16px;
  }
  @media (max-width: 768px) {
    margin-top: 12px;
    gap: 12px;
  }
  @media (max-width: 430px) {
    padding: 0 24px 0 24px;
    gap: 8px;
    margin-top: 8px;
    grid-template-areas:
      'I I J'
      'L M N'
      'L M O'
      'Q R P'
      'T R P'
      'S S S'
      'K V U';
  }

  @media (max-width: 320px) {
    padding: 0 16px 0 16px;
    gap: 6px;
    margin-top: 6px;
  }

  div:nth-child(1) {
    grid-area: I;
  }

  div:nth-child(2) {
    grid-area: J;
  }

  div:nth-child(3) {
    grid-area: K;
  }

  div:nth-child(4) {
    grid-area: L;
  }

  div:nth-child(5) {
    grid-area: M;
  }

  div:nth-child(6) {
    grid-area: N;
  }

  div:nth-child(7) {
    grid-area: O;
  }

  div:nth-child(8) {
    grid-area: P;
  }

  div:nth-child(9) {
    grid-area: Q;
  }

  div:nth-child(10) {
    grid-area: R;
  }

  div:nth-child(11) {
    grid-area: S;
  }

  div:nth-child(12) {
    grid-area: T;
  }

  div:nth-child(13) {
    grid-area: U;
  }

  div:nth-child(14) {
    grid-area: V;
  }

  div {
    img {
      width: 100%;
      height: 100%;
    }
  }

  div:hover {
    cursor: pointer;
  }
}
