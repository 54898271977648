@import '../../styles/colors';
@import '../../styles/resolutions';

.Nav {
  padding: 8px 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 99;
  width: 100%;
  backdrop-filter: blur(9px);
  color: $WHITE;
  transition:
    margin-top 0.3s ease-in-out,
    opacity 0.2s ease-in-out;

  @media (max-width: 1154px) {
    padding: 8px 100px;
  }

  @media (max-width: $TABLET_LANDSCAPE) {
    padding: 8px 32px;
  }

  .leftSide {
    display: flex;
    align-items: center;

    img {
      cursor: pointer;
    }

    .desktopList {
      display: flex;
      align-items: center;
      margin-left: 60px;

      @media (max-width: $TABLET_LANDSCAPE) {
        display: none;
      }

      li {
        box-sizing: content-box;
        padding: 8px;
        font-size: 18px;
        cursor: pointer;

        a {
          transition: color 0.2s ease-in-out;
        }

        a:hover,
        a.active {
          color: $BEIGE;
        }
      }
    }
  }

  .rightSide {
    display: flex;
    align-items: center;

    @media (max-width: $TABLET_LANDSCAPE) {
      display: none;
    }
  }

  .burgerMenu {
    display: none;

    path {
      transition: stroke 0.2s ease-in-out;
    }

    @media (max-width: $TABLET_LANDSCAPE) {
      display: block;
    }
  }

  .burgerMenu:hover {
    path {
      stroke: $BEIGE;
    }
  }
}

Nav.scrolled {
  margin-top: -68px;
  opacity: 0;
}

.closeBurgerMenu {
  position: absolute;
  top: 23px;
  right: 32px;
  z-index: 10;
}

.mobileMenuContainer {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  backdrop-filter: blur(9px);

  .mobileList {
    padding-top: calc(32px + 68px);
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      text-align: center;
      padding: 8px;

      a {
        transition: color 0.2s ease-in-out;
      }

      :hover {
        color: $BEIGE;
      }
    }
  }
}

.supportMobileContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;

  .contactUs {
    margin-top: 12px;
  }
}

.instagramContainer {
  cursor: pointer;
  padding: 4px;
  display: flex;
  justify-content: center;

  .instagramIcon {
    path {
      transition: stroke 0.2s ease-in-out;
    }
  }
}

.instagramContainer:hover {
  path {
    stroke: $BEIGE;
  }
}

.contactUs {
  cursor: pointer;
  border-radius: 23px;
  padding: 8px 24px;
  border: 1px solid $WHITE;
  margin-left: 8px;
  display: flex;
  align-items: center;
  color: $WHITE;
  width: max-content;
  transition:
    border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  .whatsappIcon {
    margin-left: 16px;

    path {
      transition: fill 0.2s ease-in-out;
    }
  }
}

.contactUs:hover {
  color: $BEIGE;
  border-color: $BEIGE;

  path {
    fill: $BEIGE;
  }
}
