@import '../../../../styles/colors';

.ChangeLanguageDesktop {
  cursor: pointer;
  margin-left: 24px;
  display: flex;
  align-items: center;
  font-size: 14px;

  .inactiveLanguage {
    color: #acadb4;
  }

  .activeLanguage {
    color: $WHITE;
  }

  .lang {
    cursor: pointer;
  }

  .languages {
    position: fixed;
    top: 68px;
    right: 180px;
    cursor: default;
    display: flex;
    gap: 8px;
    flex-direction: column;
    text-align: right;
    padding-right: 20px;

    div:hover {
      color: $WHITE;
    }

    @media (max-width: 1154px) {
      right: 119px;
    }
  }

  img {
    margin-left: 4px;
  }
}
