@import '../../../../styles/resolutions';

.Photos {
  display: flex;
  gap: 16px;
  opacity: 0.6;

  .imageContainer {
    width: 20%;
    position: relative;

    @media (max-width: $TABLET_LANDSCAPE) {
      width: 31%;
    }

    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    }

    img {
      width: 100%;
      filter: sepia(0.5);
    }
  }
}
