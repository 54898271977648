.GridPhotos {
  margin-top: 64px;
  display: grid;
  gap: 24px;
  padding: 0 34px 0 34px;
  grid-template-areas:
    'A B C C'
    'D B E F'
    'G H E F';

  @media (max-width: 1024px) {
    margin-top: 10px;
    gap: 16px;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    gap: 12px;
  }

  @media (max-width: 430px) {
    padding: 0 24px 0 24px;
    gap: 8px;
    grid-template-areas:
      'B C C'
      'B E F'
      'H E F'
      'A D G';
  }
  @media (max-width: 320px) {
    padding: 0 16px 0 16px;
    gap: 6px;
  }

  div:nth-child(1) {
    grid-area: A;
  }

  div:nth-child(2) {
    grid-area: B;
  }

  div:nth-child(3) {
    grid-area: C;
  }

  div:nth-child(4) {
    grid-area: D;
  }

  div:nth-child(5) {
    grid-area: E;
  }

  div:nth-child(6) {
    grid-area: F;
  }

  div:nth-child(7) {
    grid-area: G;
  }

  div:nth-child(8) {
    grid-area: H;
  }

  div {
    img {
      width: 100%;
      height: 100%;
    }
  }

  div:hover {
    cursor: pointer;
  }
}
