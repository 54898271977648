@import '../../styles/colors';

.Controls {
  display: flex;
  align-items: center;

  .prev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $WHITE;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    cursor: pointer;
  }

  .dotsContainer {
    height: 40px;
    border-radius: 23px;
    border: 1px solid $WHITE;
    display: flex;
    justify-content: space-between;
    padding-left: 13px;

    .dots {
      display: flex;
      align-items: center;
      gap: 4px;

      .dot {
        width: 8px;
        height: 8px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
      }

      .activeDot {
        background: $WHITE;
        width: 12px;
        height: 12px;
      }
    }

    .next {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: -5px;
    }
  }
}
