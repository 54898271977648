@import '../../styles/colors';
@import '../../styles/resolutions';

.Services {
  padding: 180px 180px 0 180px;
  position: relative;

  @media (max-width: $TABLET_LANDSCAPE) {
    padding: 160px 32px 0 32px;
  }

  @media (max-width: 578px) {
    padding: 100px 32px 0 32px;
  }

  .consultation {
    position: absolute;
    bottom: 32px;
    left: 32px;
    width: calc(100% - 64px);
    display: flex;
    align-items: center;
    color: $WHITE;
    z-index: 3;
    cursor: pointer;
    border: 1px solid $WHITE;
    border-radius: 23px;
    padding: 8px 24px;
    justify-content: center;
    transition:
      border-color 0.2s ease-in-out,
      color 0.2s ease-in-out;

    .whatsappIcon {
      margin-left: 16px;

      path {
        transition: fill 0.2s ease-in-out;
      }
    }
  }

  .consultation:hover {
    border-color: $BEIGE;
    color: $BEIGE;

    .whatsappIcon {
      path {
        fill: $BEIGE;
      }
    }
  }

  .services {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 100px;
    color: $WHITE;

    div:nth-child(1) {
      font-family: Missionary-Regular, serif;

      @media (max-width: 698px) {
        display: flex;
        text-align: center;
      }
    }

    @media (max-width: 500px) {
      font-size: 80px;
    }

    @media (max-width: 698px) {
      flex-direction: column;
    }
  }

  .servicesBackground {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 300px;
    color: $BEIGE;
    font-style: italic;
    font-family: Missionary-Regular;
    opacity: 0.1;
    z-index: -1;
    cursor: default;
    user-select: none;

    @media (max-width: 1162px) {
      font-size: 250px;
    }

    @media (max-width: 957px) {
      font-size: 200px;
    }

    @media (max-width: 779px) {
      font-size: 150px;
    }

    @media (max-width: 578px) {
      top: 170px;
    }
  }

  .swiper {
    width: 100%;
  }

  .cardImageContainer {
    position: relative;
    width: 400px;

    @media (max-width: 901px) {
      width: 100%;
    }

    .cardTitle {
      position: absolute;
      top: 32px;
      left: 32px;
      z-index: 2;
      font-size: 32px;
      color: $WHITE;
      font-family: Missionary-Regular, serif;
    }

    .cardSubtitle {
      font-style: italic;
      color: $BEIGE;
      font-size: 28px;
    }

    .cardDescription {
      position: absolute;
      top: 170px;
      left: 32px;
      z-index: 2;
      max-width: calc(100% - 64px);
      font-family: Raleway-Regular, serif;
      color: $WHITE;
    }

    .cardSubBackground {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.9)
      );
      z-index: 1;
    }
  }

  .cardImage {
    width: 100%;
    border-radius: 24px;
    filter: sepia(0.6);
  }

  .serviceSlicerContainer {
    margin-top: 40px;
  }
}
